<template>
	<div id="classSchedule">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教学</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">课表查询</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="nav-tab-bar-box u-f-item u-f-jsb">
				<div class="nav-tab-bar u-f-item">
					<div
						class="font_14 nav-tab-item"
						@click="twacherTap(index)"
						:class="index == tabIndex ? 'active' : ''"
						v-for="(item, index) in menu"
						:key="index"
					>
						{{ item.name }}
					</div>
				</div>
			</div>
			<div class="u-f-item u-f-jsb head">
				<div class="u-f-item" v-if="tyepIndex==0">
					<div>班级：</div>
					<el-select  v-model="class_id" filterable placeholder="班级" size="small" style="width: 200px;margin-right: 10px;" @change="classChange">
						<el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="u-f-item" v-if="tyepIndex==1">
					<div>教师：</div>
					<el-select  v-model="teacher_id" filterable placeholder="教师" size="small" style="width: 200px;margin-right: 10px;"  @change="teacherChange">
						<el-option v-for="item in teachersList" :key="item.user_id" :label="item.username" :value="item.user_id"></el-option>
					</el-select>
				</div>
				<div class="main-tab-bars u-f-item">
					<div class="main-tab-item" :class="{ active: tyepIndex == 0 }" @click="tabTap(0)">班级课表</div>
					<div class="main-tab-item" :class="{ active: tyepIndex == 1 }" @click="tabTap(1)">教师课表</div>
				</div>
			</div>
			<div class="content">
				<div class="u-f table" style="border-top: 1px solid #EEEEEE;">
					<div class="th-1 u-f-justify u-f-column"></div>
					<div class="th u-f-justify u-f-column" v-for="(item, index) in attend_day" :key="index">
						<div>{{item.name}}</div>
						<div style="margin-top: 10px;">{{item.date}}</div>
					</div>
				</div>
				<div class="u-f table" v-for="(item,index) in curriculum_time" :key="index">
					<div class="th-1 u-f-justify u-f-column">
						<div>{{item.node}}</div>
						<div>{{item.start}}~{{item.end}} </div>
					</div>
					<div class="th u-f-justify u-f-column" :class="val.class?'active':''" v-for="(val,i) in item.list" :key="i">
						<el-tooltip placement="bottom">
							<div slot="content">
								<div style="border-bottom: 1px dashed #fff;padding-bottom:10px;margin-bottom:10px">班级：{{val.class_name||''}}</div>
								<div>学科：{{val.school_discipline_name||''}}</div>
								<div>教师：{{val.user_name||''}}</div>
							</div>
							<div>
								<div>{{val.class_name||''}}</div>
								<div>{{val.school_discipline_name||''}}</div>
								<div>{{val.user_name||'-'}}</div>
							</div>
						</el-tooltip>
					</div>
				</div>
			</div>
		</index>	
	</div>
</template>

<script>
import index from '@/components/common/index.vue'
export default {
	components: {
		index
	},
	data() {
		return {
			tabIndex: 1,
			menu: [
				{name: '实时课表'},
				{name: '初始课表'},
			],
			typeList:[
				{name:'班级课表'},
				{name:'教师课表'}
			],
			typeIndex:0,
			attend_day:[],
			curriculum_time: [],
			classList:[],
			class_id:[],
			tyepIndex:0,
			teachersList:[],
			teacher_id:''
		};
	},
	mounted() {
		this.getClassList()
	},
	methods: {
		teacherChange(){
			this.getcourseLists()
		},
		classChange(){
			this.getcourseLists()
		},
		tabTap(index){
			this.tyepIndex = index;
			if(index==0){
				this.getcourseLists()
			}else if(index==1){
				this.getteacherList()
			}
		},
		twacherTap(index){
			this.tabIndex = index;
			if(index==0){
				this.$router.push('/classSchedule')
			}else{
				this.$router.push('/initialCourse')
			}
		},
		unique2(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr.teacher_id) && res.set(arr.teacher_id, 1));
		},
		// 教师列表
		getteacherList() {
			this.$api.pepople.teacherList({}).then(res => {
				if (res.data.code == 1) {
					let data = res.data.data;
					for (let i in data) {
						for (let j in data[i].data) {
							for (let t in data[i].data[j].teacher) {
								this.teachersList = this.teachersList.concat(data[i].data[j].teacher[t]);
							}
						}
					}
					this.teachersList = this.unique2(this.teachersList);
					this.teacher_id = this.teachersList[0].user_id;
					this.getcourseLists()
				}
			});
		},
		getClassList(){
			this.$api.setting.getClassList({}).then(res=>{
				if(res.data.code==1){
					this.classList = res.data.data.rows;
					this.class_id = res.data.data.rows[0].id;
					this.getcourseLists()
				}
			})
		},
		getcourseLists(){
			let data = {
				type:2
			}
			console.log(this.tyepIndex)
			if(this.tyepIndex==0){
				data.class_id = this.class_id;
			}else{
				console.log(this.teacher_id)
				data.teacher_id = this.teacher_id;
			}
			this.$api.paike.getcourseLists(data).then(res=>{
				if(res.data.code==1){
					this.attend_day = res.data.data.attend_day;
					let curriculum_time = res.data.data.curriculum_time;
					let list = [];
					if(res.data.data.data){
						list = res.data.data.data
					}
					for(let i=0;i<curriculum_time.length;i++){
						this.$set(curriculum_time[i], 'list', [])
						for(let j=0;j<this.attend_day.length;j++){
							curriculum_time[i].list.push({xq: this.attend_day[j].name, node: curriculum_time[i].node})
							let arr = list.filter(item=>item.attend_day == this.attend_day[j].name && item.node == curriculum_time[i].node);
							if(arr.length){
								curriculum_time[i].list[j].school_discipline_name = arr[0].school_discipline_name;
								curriculum_time[i].list[j].class_name = arr[0].class_name;
								curriculum_time[i].list[j].user_name = arr[0].user_name;
							}
						}
					}
					console.log(curriculum_time)
					this.curriculum_time = curriculum_time;
				}
			})
		}
	}
};
</script>

<style lang="scss" scope>
	#classSchedule{
		.nav-tab-bar-box {
			background-color: #ffffff;
			padding: 0.52vw;
			margin-top: 1.04vw;
			.nav-tab-bar {
				.nav-tab-item {
					color: #6e7a87;
					padding: 0 0.78125vw;
					border-right: 1px solid #f3f3f3;
					cursor: pointer;
					-webkit-touch-callout: none;
					-webkit-user-select: none;
					-khtml-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
				}
				&div:last-child {
					border-right: none;
				}
				.active {
					font-size: 0.833vw;
					color: #63acff;
					position: relative;
					font-weight: bold;
				}
				.active::after {
					position: absolute;
					content: '';
					height: 2px;
					left: 0.78125vw;
					right: 0.78125vw;
					background-color: #63acff;
					bottom: -0.58125vw;
				}
			}
		}
        .head {
            background-color: #ffffff;
            padding: 10px 30px;
            margin-top: 15px;
            .span {
                margin-right: 10px;
                background-color: #f9f9f9;
                color: #8d9da6;
                padding: 3px 10px;
            }
			.main-tab-bars {
				border: 1px solid #dadfe0;
				.main-tab-item {
					height: 30px;
					line-height: 30px;
					width: 100px;
					text-align: center;
					cursor: pointer;
					-webkit-touch-callout: none;
					-webkit-user-select: none;
					-khtml-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
				}
				.active {
					color: #67adf4;
					background-color: #e1f3ff;
					border: 1px solid #67adf4;
				}
			}
        }
        .content {
            background-color: #ffffff;
            margin-top: 20px;
			.table {
				padding: 0;
				.th-1 {
					width: 8%;
					text-align: center;
					height: 90px;
					border-right: 1px solid #EEEEEE;
					border-bottom: 1px solid #EEEEEE;
				}
				.th {
					width: 13.5%;
					text-align: center;
					height: 90px;
					border-right: 1px solid #EEEEEE;
					border-bottom: 1px solid #EEEEEE;
				}
				.active{
					border: 1px solid #FF6B5A;
					background-color: #EEFAF6;
				}
			}
            .title {
				padding: 0 0 15px 0;
				.btn {
					background-color: #f3f3f3;
					font-size: 12px;
					padding: 5px 10px;
					color: #555555;
					cursor: pointer;
				}
			}
			.el-table th > .cell,
			.el-table .cell {
				overflow: hidden; /*超出部分隐藏*/
				white-space: nowrap; /*不换行*/
				text-overflow: ellipsis; /*超出部分文字以...显示*/
			}
			.el-table thead {
				background: #fafafa !important;
			}
			.el-table th {
				padding: 15px 5px !important;
				background: #fafafa !important;
			}
			.el-table::before {
				left: 0;
				bottom: 0;
				width: 100%;
				height: 0;
			}
			.el-table .btn{
				padding: 5px;
				text-align: center;
			}
        }
        .el-dialog__body {
            padding: 20px 20px 50px;
            .edit-box {
                .input-box {
                    margin-top: 35px;
                    .input-lebal {
                        width: 15%;
                        // text-align: right;
                        color: #aeb5b8;
                        margin-right: 10px;
                        span {
                            color: #ff7a65;
                            margin-right: 3px;
                        }
                    }
                    .el-icon-remove-outline {
                        font-size: 1.5625vw;
                        color: #d7d9db;
                        margin-left: 10px;
                    }
                }
				.upload-file{
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					opacity: 0;
					cursor: pointer;
				}
            }
        }
        .el-dialog__footer {
            padding: 15px 20px 15px;
            box-shadow: 0px -5px 10px -5px #eeeeee;
        }
    }
	
</style>
